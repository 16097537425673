import { useCallback } from "react";
import { sendGAEvent } from '@next/third-parties/google';

function useLogEvent() {
    return useCallback((...params: any) => {
        if (typeof params === "string") sendGAEvent({ event: params });
        else if ("event" in params) sendGAEvent(params);
    }, []);
};

export default useLogEvent;
