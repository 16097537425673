import BusinessesSearchBox from "components/BusinessesSearchBox";
import Image from "next/legacy/image";
import React from "react";
import Clocks from "./Clocks.svg";
import Papers from "./Papers.svg";

const SearchBox: React.FC<{ className?: string }> = ({ className }) => {
    return (
        <div
            id="searchSection"
            className={className}
            style={{
                backgroundColor: "#F1F1F1",
                width: "100%",
                height: 200,
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 18,
                    width: 190,
                    height: 190,
                    mixBlendMode: "multiply",
                }}
            >
                <Image alt="Papers, notes and calendars" src={Papers} />
            </div>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    right: 18,
                    width: 190,
                    height: 190,
                    mixBlendMode: "multiply",
                }}
            >
                <Image layout="fill" alt="Calendars and gears" src={Clocks} />
            </div>
            <div className="container">
                <BusinessesSearchBox />
            </div>
        </div>
    );
};

export default SearchBox;
