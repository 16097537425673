import React from "react";
import TuturnoBrand from "components/TuturnoBrand";
import HeaderSubtitle from "components/HeaderSubtitle";
import Header from "components/Header";
import Highlights from "./Highlights";
import Featured from "./Featured";
import Head from "next/head";
import Welcome from "./Welcome";
import SearchBox from "./SearchBox";
import ExploreCategories from "./ExploreCategories";

const Home: React.FC = () => {
    return (
        <div className="bg-light overflow-hidden">
            <Head>
                <title>Tuturno</title>
                <meta
                    name="description"
                    content="Plataforma online para que cualquier organización pueda ofrecer sus servicios."
                />
            </Head>
            <Header fluid={false}>
                <TuturnoBrand className="me-lg-3" />
                <HeaderSubtitle>Inicio</HeaderSubtitle>
            </Header>
            <div className="container my-3 my-lg-5">
                <Welcome />
            </div>
            <SearchBox className="mb-5" />
            <div className="container mb-5">
                <ExploreCategories />
                <Featured />
            </div>
            <Highlights />
        </div>
    );
};

export default Home;
