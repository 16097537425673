import { ReactNode } from "react";

export interface HeaderSubtitleProps {
    className?: string;
    children?: ReactNode;
}

export const HeaderSubtitle: React.FC<HeaderSubtitleProps> = ({
    className,
    children,
}) => {
    return (
        <h2 className={`ms-2 text-muted h5 ${className} mb-0 fw-light`}>
            <span> | </span>
            {children}
        </h2>
    );
};

export default HeaderSubtitle;
