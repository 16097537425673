import FormSelectInput from "@tuturno/tuturno-ui/components/FormSelectInput";
import { FormSearchBoxFields } from "components/BusinessesSearchBox";
import { FormBusinessesSearchFields } from "pages/search/useBusinessSearch";
import React from "react";
import { FormState, UseFormRegister } from "react-hook-form";

interface SupportedCitiesDropdownProps {
    name: string;
    register:
        | UseFormRegister<FormSearchBoxFields>
        | UseFormRegister<FormBusinessesSearchFields>;
    formState: FormState<FormSearchBoxFields>;
    className: string;
    containerClassName: string;
    validate: (value: string | number) => boolean | string;
    defaultValue: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SupportedCitiesDropdown: React.FC<SupportedCitiesDropdownProps> = ({
    name,
    register,
    formState,
    className,
    containerClassName,
    validate,
    defaultValue,
    onChange,
}) => {
    return (
        <FormSelectInput
            name={name}
            register={register}
            formState={formState}
            className={className}
            containerClassName={containerClassName}
            validate={validate}
            defaultValue={defaultValue}
            onChange={onChange}
        >
            <option disabled value={"-1"}>
                Seleccionar su ubicación
            </option>
            <option value={"sf"}>SF</option>
            <option value={"ros"}>ROS</option>
            <option value={"cf"}>CF</option>
            <option value={"cor"}>COR</option>
            <option value={"men"}>MEN</option>
            <option value={"par"}>PAR</option>
        </FormSelectInput>
    );
};

export default SupportedCitiesDropdown;
