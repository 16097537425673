import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";
import Barber from "./Barber.webp";
import HighlightCard from "./components/HighlightCard";
import classes from "./highlights.module.css";

const Highlights: React.FC = () => {
    return (
        <div className="bg-background">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6 py-4 pe-0 pe-md-4">
                        <h3
                            className={`${classes.title} text-center text-md-start`}
                        >
                            ¿Querés ofrecer
                            <br /> tus servicios?
                        </h3>
                        <HighlightCard
                            text="Destacá con un sistema de turnos fácil de usar."
                            iconName="MarketingStar"
                            className="mt-4"
                        />
                        <HighlightCard
                            text="Reducí las ausencias gracias a nuestro sistema de notificaciones inteligentes."
                            iconName="MarketingBell"
                            className="mt-4"
                        />
                        <HighlightCard
                            text="Ofrecé pagos online con tarjeta y MercadoPago sin complicaciones."
                            iconName="MarketingCreditCard"
                            className="mt-4"
                        />
                        <HighlightCard
                            text="Recibí analíticas personalizadas para tu negocio"
                            iconName="MarketingPulse"
                            className="mt-4"
                        />
                        <div className="d-flex justify-content-center justify-content-md-start mt-4">
                            <div
                                style={{ width: 30 }}
                                className="d-none d-md-block m-3"
                            />
                            <Link
                                href="/register"
                                className="btn btn-primary mt-3 mt-lg-0"
                                style={{ marginLeft: "1rem" }}
                            >
                                Registrarme
                            </Link>
                            <Link
                                href="/blog"
                                className="btn btn-light text-primary ms-lg-4 mt-3 mt-lg-0"
                                style={{ marginLeft: "1rem" }}
                            >
                                Conocer más
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 pt-4 d-flex align-items-end justify-content-center justify-content-md-end">
                        <Image
                            src={Barber}
                            style={{ transform: "translateY(3px)" }}
                            alt="Barber cutting someone's hair"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Highlights;
