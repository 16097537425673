import { useEffect, useState } from "react";

interface PlaceholderState {
    wordIndex: number;
    letterIndex: number;
}

const usePlaceHolderAnimation = (texts: Array<string>): string => {
    const [placeholderState, setPlaceholderState] = useState<PlaceholderState>({
        wordIndex: 0,
        letterIndex: 0,
    });

    useEffect(() => {
        const currentWord: string = texts[placeholderState.wordIndex];
        let timeout: NodeJS.Timeout;
        if (placeholderState.letterIndex + 1 < currentWord.length) {
            // Advance one letter.
            timeout = setTimeout(() => {
                setPlaceholderState((x) => ({
                    wordIndex: x.wordIndex,
                    letterIndex: x.letterIndex + 1,
                }));
            }, 150 + (Math.random() * 2 - 1) * 50);
        } else {
            // Advance one word.
            timeout = setTimeout(() => {
                setPlaceholderState((x) => ({
                    wordIndex: (x.wordIndex + 1) % texts.length,
                    letterIndex: -1,
                }));
            }, 1500);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [placeholderState, texts]);

    return (
        texts[placeholderState.wordIndex].substr(
            0,
            placeholderState.letterIndex + 1
        ) + "|"
    );
};

export default usePlaceHolderAnimation;
