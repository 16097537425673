import React, { useEffect, useState } from "react";
import usePlaceHolderAnimation from "helpers/usePlaceHolderAnimation";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import Icon from "@tuturno/tuturno-ui/components/Icon";
import { FormData } from "types/FormData";
import { getPosition } from "helpers/getPosition";
import { getDistance } from "helpers/API/math";
import { locationData } from "components/SupportedCitiesDropdown/locationData";
import SupportedCitiesDropdown from "components/SupportedCitiesDropdown";

const placeHolderTexts = [
    "Peluquería",
    "Restaurante",
    "Gimnasio",
    "Cardióloga",
    "Manicura",
];

interface BusinessesSearchBoxProps {
    formClassNames?: string;
    initialValue?: string;
}

export interface FormSearchBoxFields extends FormData {
    search: string;
}

const BusinessesSearchBox: React.FC<BusinessesSearchBoxProps> = (props) => {
    const [selectedLocation, setSelectedLocation] = useState<any>("");
    const placeHolderText = usePlaceHolderAnimation(placeHolderTexts);
    const router = useRouter();
    const { register, handleSubmit, setValue, formState } =
        useForm<FormSearchBoxFields>();
    const onSubmit = (data: { search: string }) => {
        if (position) {
            router.push(
                `/search/${data.search}?latitude=${position.latitude}&longitude=${position.longitude}`
            );
        } else {
            router.push(`/search/${data.search}`);
        }
    };

    /** The following sets the value of the search field on the first render,
     * only if the relevant prop is provided. This is used for handling
     * transition between the searchBox in the home screen and the searchBox in
     * the search screen. */
    useEffect(() => {
        if (props.initialValue) setValue("search", props.initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchPosition = async () => {
            const result = await getPosition();
            if (result) {
                setPosition(result);
                const distances = Object.entries(locationData).map(
                    ([key, location]) => {
                        const distance = getDistance(
                            parseFloat(result.latitude),
                            parseFloat(result.longitude),
                            parseFloat(location.latitude),
                            parseFloat(location.longitude)
                        );
                        return { key, distance };
                    }
                );
                const closestLocation = distances.reduce((prev, curr) =>
                    prev.distance < curr.distance ? prev : curr
                );
                setSelectedLocation(closestLocation.key);
                setValue("location", closestLocation.key);
            } else {
                setSelectedLocation("");
                setValue("location", "-1");
            }
        };
        fetchPosition();
    }, []);

    const [position, setPosition] = useState<{
        latitude: string;
        longitude: string;
    } | null>(null);

    return (
        <form
            className={`m-0 p-0 ${props.formClassNames ?? ""}`}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className="m-0 ps-0 pe-4 py-1 input-group focus-within-color-primary d-flex rounded-pill bg-white align-items-center">
                <div className="input-group-append">
                    <button
                        className="btn btn-transparent shadow-none h-100 border-0 rounded-pill rounded-end ps-4 d-flex align-items-center fill-primary fill-warning-hover"
                        type="submit"
                    >
                        <Icon
                            name="Search"
                            width={24}
                            height={24}
                            className="fill-inherit"
                        />
                    </button>
                </div>
                <input
                    type="text"
                    className="form-control m-0 fs-4 border-0 rounded-pill rounded-start focus-color-none shadow-none"
                    data-testid="businessesSearchBox"
                    aria-label="Campo de búsqueda"
                    placeholder={placeHolderText}
                    {...register("search", { required: true })}
                />
                <SupportedCitiesDropdown
                    name="location"
                    register={register}
                    formState={formState}
                    className="fw-light"
                    containerClassName="p-1 w-25"
                    validate={(value) =>
                        value !== "-1" ||
                        "Necesitamos que seleccione una opción"
                    }
                    defaultValue={selectedLocation}
                    onChange={(e) => {
                        const coords = locationData[e.target.value];
                        if (coords) {
                            setPosition(coords);
                        }
                    }}
                />
            </div>
        </form>
    );
};

export default BusinessesSearchBox;
