import React from "react";
import BusinessTypePill from "./BusinessTypePill";

const ExploreCategories: React.FC = () => {
    return (
        <div className={`d-flex flex-column align-items-center text-center`}>
            <h2 className="fw-bold mb-3 text-primary">
                Explorá algunas de nuestras categorías
            </h2>
            <div className="mt-4 w-100 d-flex justify-content-center flex-wrap bg-background p-3 pb-0 pe-0">
                <BusinessTypePill
                    iconName="BusinessTypeBeauty"
                    text="Belleza"
                    searchString="belleza"
                />
                <BusinessTypePill
                    iconName="BusinessTypeGym"
                    text="Gimnasio"
                    searchString="gimnasio"
                />
                <BusinessTypePill
                    iconName="BusinessTypeFood"
                    text="Gastronomía"
                    searchString="gastronomía"
                />
                <BusinessTypePill
                    iconName="BusinessTypeHealth"
                    text="Salud"
                    searchString="consultorio"
                />
                <BusinessTypePill
                    iconName="BusinessTypeHairDresser"
                    text="Peluquería"
                    searchString="peluquería"
                />
            </div>
        </div>
    );
};

export default ExploreCategories;
