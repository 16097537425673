import Icon from "@tuturno/tuturno-ui/components/Icon";
import * as Icons from "@tuturno/tuturno-ui/components/Icon/icons";
import { getPosition } from "helpers/getPosition";
import useLogEvent from "helpers/useLogEvent";
import Link from "next/link";
import {
    AnchorHTMLAttributes,
    DetailedHTMLProps,
    useEffect,
    useState,
} from "react";

export interface BusinessTypePillProps
    extends DetailedHTMLProps<
        AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    > {
    iconName: keyof typeof Icons;
    searchString: string;
    text: string;
}

const BusinessTypePill: React.FC<BusinessTypePillProps> = ({
    className,
    iconName,
    text,
    searchString,
    ...props
}) => {
    const logEvent = useLogEvent();
    const [position, setPosition] = useState<{
        longitude: string;
        latitude: string;
    } | null>(null);

    useEffect(() => {
        const fetchPosition = async () => {
            const result = await getPosition();
            if (result) {
                setPosition(result);
            }
        };
        fetchPosition();
    }, []);

    return (
        <Link
            legacyBehavior
            href={`/search/${searchString as string}?latitude=${
                position?.latitude
            }&longitude=${position?.longitude}`}
        >
            <a
                className={`
					rounded bg-light
					mb-3 me-3
					p-2 px-md-4 py-md-3
					d-flex align-items-center
					text-decoration-none
					link-dark
					${className}
				`}
                {...props}
                onClick={() =>
                    logEvent("clicked_home_category", { category: text })
                }
            >
                <Icon
                    name={iconName}
                    className="fill-primary"
                    width={30}
                    height={30}
                />
                <span className="ms-2 h4 p-0 m-0">{text}</span>
            </a>
        </Link>
    );
};

export default BusinessTypePill;
