import React, { useEffect, useRef, useState } from "react";
import useFeaturedBusinesses from "./useFeaturedBusinesses";
import Link from "next/link";
import Icon from "@tuturno/tuturno-ui/components/Icon";

const Featured: React.FC = () => {
    const [, businesses] = useFeaturedBusinesses();
    const scrollRef = useRef<HTMLDivElement>(null);
    const lastBusinessRef = useRef<HTMLDivElement>(null);
    const [hasMoreOnRight, setHasMoreOnRight] = useState(true);
    const [hasMoreOnLeft, setHasMoreOnLeft] = useState(false);
    const disableMovement = useRef(false);
    const [hover, setHover] = useState(false);

    const scroll = (direction: "right" | "left") => async () => {
        if (scrollRef.current && !disableMovement.current) {
            disableMovement.current = true;
            scrollRef.current.scrollLeft +=
                scrollRef.current.offsetWidth *
                (direction === "right" ? 1 : -1);
            await new Promise((res) => setTimeout(res, 500));
            if (scrollRef.current.scrollLeft < 50) {
                setHasMoreOnLeft(false);
            } else {
                setHasMoreOnLeft(true);
            }
            if (lastBusinessRef.current) {
                if (
                    lastBusinessRef.current.offsetLeft +
                        100 -
                        scrollRef.current.offsetWidth <
                    scrollRef.current.scrollLeft
                ) {
                    setHasMoreOnRight(false);
                } else {
                    setHasMoreOnRight(true);
                }
            }
            disableMovement.current = false;
        }
    };

    useEffect(() => {
        function handleWheel(e: WheelEvent) {
            if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
                e.preventDefault();
            }
        }
        if (scrollRef.current) {
            scrollRef.current.addEventListener("wheel", handleWheel);
            return () => {
                document.removeEventListener("wheel", handleWheel);
            };
        }
    }, []);

    return (
        <div
            className={`overflow-visible position-relative`}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}
        >
            <h2 className="text-dark fw-bold mb-4 mt-5 text-center text-md-start">
                Los destacados de hoy
            </h2>
            <div
                className="row gx-3 gy-3 flex-md-nowrap hide-scrollbar overflow-scroll"
                style={{ scrollBehavior: "smooth" }}
                ref={scrollRef}
            >
                {businesses.map((business, i, { length }) => (
                    <div
                        key={business.id}
                        ref={i === length - 1 ? lastBusinessRef : null}
                        className="col-12 col-md-6 col-md-4 col-lg-3"
                    >
                        <div>
                            <Link
                                href={`/business/${business.alias}`}
                                className="text-decoration-none"
                            >
                                <div
                                    style={{
                                        width: "100%",
                                        aspectRatio: "16 / 13",
                                        backgroundImage: `url(${
                                            business.cover ??
                                            "https://images.ctfassets.net/hrltx12pl8hq/5GaLeZJlLyOiQC4gOA0qUM/a0398c237e9744ade8b072f99349e07a/shutterstock_152461202_thumb.jpg?fit=fill&w=480&h=270"
                                        })`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                    }}
                                />
                                <h4 className="mt-3 text-dark text-truncate">
                                    {business.name}
                                </h4>
                                <h5 className="text-muted text-truncate">
                                    {business.type}
                                </h5>
                            </Link>
                            <Link
                                href={`/business/${business.alias}`}
                                className="btn btn-outline-primary mt-3 mb-1 btn-sm"
                            >
                                Ver más
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            {hasMoreOnLeft && hover && (
                <button
                    className="bg-secondary shadow d-none d-md-flex align-items-center justify-content-center"
                    onClick={scroll("left")}
                    style={{
                        all: "unset",
                        cursor: "pointer",
                        position: "absolute",
                        left: -35,
                        width: 60,
                        height: 60,
                        fontSize: 30,
                        lineHeight: 1.1,
                        textAlign: "center",
                        top: "50%",
                        borderRadius: 30,
                    }}
                >
                    <Icon
                        name="ArrowLeft"
                        width={25}
                        height={25}
                        style={{ transform: "translateY(1px)" }}
                    />
                </button>
            )}
            {hasMoreOnRight && hover && (
                <button
                    className="bg-secondary shadow d-none d-md-flex align-items-center justify-content-center"
                    onClick={scroll("right")}
                    style={{
                        all: "unset",
                        cursor: "pointer",
                        position: "absolute",
                        right: -35,
                        width: 60,
                        height: 60,
                        fontSize: 30,
                        lineHeight: 1.1,
                        textAlign: "center",
                        top: "50%",
                        borderRadius: 30,
                    }}
                >
                    <Icon
                        name="ArrowRight"
                        width={25}
                        height={25}
                        style={{ transform: "translateY(1px)" }}
                    />
                </button>
            )}
        </div>
    );
};

export default Featured;
