interface Location {
    latitude: string;
    longitude: string;
}

export const locationData: Record<string, Location> = {
    sf: { latitude: "-31.634160", longitude: "-60.705562" },
    ros: { latitude: "-32.956451", longitude: "-60.664011" },
    cf: { latitude: "-34.603836", longitude: "-58.381541" },
    cor: { latitude: "-31.413471", longitude: "-64.185619" },
    men: { latitude: "-32.879495", longitude: "-68.853533" },
    par: { latitude: "-31.748480", longitude: "-60.509128" },
};
