import API from "./API";

export type PositionData = {
    latitude: string;
    longitude: string;
};

export const getPosition = async () => {
    try {
        const storedLatitude = localStorage.getItem("latitude");
        const storedLongitude = localStorage.getItem("longitude");

        if (storedLatitude && storedLongitude) {
            return {
                latitude: storedLatitude,
                longitude: storedLongitude,
            };
        } else {
            const response = await API.get<PositionData>(`/api/me/position`);
            if (response.code === "200" && response.data) {
                localStorage.setItem("latitude", response.data?.latitude);
                localStorage.setItem("longitude", response.data?.longitude);
            }

            return {
                latitude: response.data?.latitude!,
                longitude: response.data?.longitude!,
            };
        }
    } catch (error) {
        return null;
    }
};
