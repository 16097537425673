import { FieldError } from "react-hook-form";
import { FormInputProps } from "../FormInput";
import { forwardRef, MutableRefObject } from "react";

export interface FormSelectInputProps
    extends FormInputProps<HTMLSelectElement> {
    loading?: boolean;
}

const FormSelectInput = forwardRef<HTMLSelectElement, FormSelectInputProps>(
    (
        {
            formState,
            register,
            name,
            required,
            pattern,
            disabled,
            min,
            max,
            maxLength,
            loading,
            minLength,
            containerClassName,
            label,
            validate,
            className,
            prepend,
            append,
            classNamePrependAppend,
            inputGroupContainerClass,
            ...props
        },
        customRef
    ) => {
        const { ref, ...registration } = register(name, {
            required,
            pattern,
            min,
            max,
            maxLength,
            minLength,
            validate,
        });
        return (
            <div
                className={`
                    ${props.hidden ? "d-none" : ""} 
                    ${containerClassName}
                `}
            >
                {label && (
                    <label htmlFor={name} className="form-label">
                        {label}
                    </label>
                )}
                <div
                    className={`
                        input-group
                        ${inputGroupContainerClass}
                    `}
                >
                    {prepend && (
                        <span
                            className={`
                                ${loading && "invisible"}
                                d-flex align-items-center flex-nowrap
                                px-2 py-auto
                                ${
                                    classNamePrependAppend ??
                                    "border border-input rounded-start"
                                }
                            `}
                        >
                            {prepend}
                        </span>
                    )}
                    <select
                        aria-required={Boolean(required)}
                        aria-label={label}
                        disabled={formState.isSubmitting || disabled}
                        id={name}
                        className={`
						form-control
						form-select 
						${formState.errors[name] && "is-invalid"}
						${className}
						${disabled ? "disabled" : ""}
						${loading ? "invisible" : ""}
					`}
                        {...registration}
                        ref={(e) => {
                            ref(e);
                            if (customRef && e) {
                                (
                                    customRef as MutableRefObject<HTMLSelectElement>
                                ).current = e;
                            }
                        }}
                        {...props}
                    >
                        {props.children}
                    </select>
                    {append && (
                        <span
                            className={`
                                ${loading && "invisible"}
                                d-flex align-items-center flex-nowrap
                                px-2 py-auto
                                ${
                                    classNamePrependAppend ??
                                    "border border-input rounded-end"
                                }
                            `}
                        >
                            {append}
                        </span>
                    )}
                    {loading && (
                        <div
                            data-testid="input-loading-animation"
                            className="position-absolute w-100 h-100 top-0 start-0 loading-animation rounded"
                        />
                    )}
                    {formState.errors[name] && (
                        <div
                            data-testid={`${name}-${
                                (formState.errors[name] as FieldError)?.type ?? "invalid"
                            }-error`}
                            className="invalid-feedback"
                        >
                            {(formState.errors[name] as FieldError)?.message}
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default FormSelectInput;
