import API from "helpers/API";
import { useEffect, useState } from "react";
import Business from "types/Business";

const useFeaturedBusinesses = (): [boolean, Business[]] => {
    const [businesses, setBusinesses] = useState<Business[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        API.get<Business[]>(`/api/businesses/featuredBusinesses`)
            .then((response) => {
                if (response.data) setBusinesses(response.data);
                else console.error(response);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return [loading, businesses];
};

export default useFeaturedBusinesses;
