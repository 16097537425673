import * as icons from "@tuturno/tuturno-ui/components/Icon/icons";
import Icon from "@tuturno/tuturno-ui/components/Icon";
import { HTMLProps, ReactNode } from "react";
import classes from "../highlights.module.css";

interface HighlightCardProps extends HTMLProps<HTMLDivElement> {
    text: ReactNode;
    iconName?: keyof typeof icons;
}

const HighlightCard: React.FC<HighlightCardProps> = ({
    text,
    iconName,
    className,
    ...props
}) => {
    return (
        <div
            className={`
                d-flex flex-row align-items-center
                ${className}
            `}
            {...props}
        >
            <div
                className={`
                    rounded-circle p-3 bg-white
                    ${iconName ? "" : "invisible"}
                `}
            >
                {iconName && (
                    <Icon
                        name={iconName}
                        className="d-block fill-primary opacity-75"
                        width={30}
                        height={30}
                    />
                )}
                {!iconName && <Icon name={"Question"} className="d-block" />}
            </div>
            <span className={classes.itemText} style={{ fontWeight: 500 }}>
                {text}
            </span>
        </div>
    );
};

export default HighlightCard;
