import React from "react";
import Button from "@tuturno/tuturno-ui/components/Button";
import Link from "next/link";
import toast from "react-hot-toast";
import useAccountForm from "pages/me/useAccountForm";
import useSendVerificationMailForm from "pages/me/useSendVerificationMail";
import useLogEvent from "helpers/useLogEvent";

const RegisterBusinessButton = () => {
    const form = useAccountForm();
    const sendVerificationMailForm = useSendVerificationMailForm();
    const logEvent = useLogEvent();
    return (
        <div className="d-flex align-items-center flex-column flex-lg-row justify-content-center justify-content-lg-start">
            {!form.user.logged && !form.user.loading ? (
                <Link
                href={`/login?comeBack=${encodeURIComponent("/register-business")}`}
                    className="btn btn-primary px-4 py-2"
                >
                    Registrá tu negocio
                </Link>
            ) : form.user.logged &&
              !form.user.loading &&
              !form?.user?.emailConfirmed ? (
                <div>
                    <a
                        className="btn btn-primary px-4 py-2"
                        onClick={() => {
                            toast.custom(
                                <form
                                    onSubmit={sendVerificationMailForm.onSubmit}
                                    className="bg-light w-25 d-flex shadow-sm"
                                    style={{
                                        borderRadius: 20,
                                        padding: 10,
                                    }}
                                >
                                    <div className="col-2 align-content-center  justify-content-center my-auto mx-auto text-center ">
                                        ✅
                                    </div>
                                    <div className="col-10">
                                        <span>
                                            Su correo electrónico aún no fue
                                            verificado. Recuerde que su negocio
                                            no será visible al público hasta que
                                            no realice la validación. Por favor,
                                            revise su bandeja de entrada. Si no
                                            lo encuentra, puede solicitar otro
                                            correo de confirmación haciendo clic
                                            aquí 👉
                                            <Button
                                                type="submit"
                                                className="mx-2"
                                                loading={
                                                    form.formState.isSubmitting
                                                }
                                                text="click"
                                            />
                                            (recuerde revisar su carpeta de
                                            spam).
                                        </span>
                                    </div>
                                </form>,
                                {
                                    duration: 10000,
                                    position: "top-right",
                                }
                            );
                        }}
                    >
                        Registrá tu negocio
                    </a>
                </div>
            ) : (
                <Link
                    href="/register-business"
                    className="btn btn-primary px-4 py-2"
                    onClick={() =>
                        logEvent("clicked_register_business_from_home")
                    }
                >
                    Registrá tu negocio
                </Link>
            )}
            <Link
                href="#searchSection"
                className="btn btn-light text-primary ms-lg-4 mt-3 mt-lg-0"
                onClick={() => logEvent("clicked_get_appointment_from_home")}
            >
                Pedí un turno
            </Link>
        </div>
    );
};

export default RegisterBusinessButton;
